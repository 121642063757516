import React from 'react';
import "./SectionReverse.styles.css"
import { Button,Card,Row,Col } from 'react-bootstrap';

/**
 * 
 */
const SectionReverse = ({title, content, image,handleButton}) => {
    return <div>
        <Row>
        <Col className="justify-content-md-center">
                <Card.Img
                        className="d-block w-100"
                        src={image}
                        alt="Second slide"
                        height="400px"
                        style={{height:"100%",minWidth: "200px"}}

                    />
            </Col>
            <Col>
                <Card
                    bg={"light"}
                    key={1}
                    text={'dark'}
                    style={{height:"100%",minWidth: "200px"}}
                    className="mb-2"
                >
                    <Card.Body>
                    <Card.Title variant={"light"}> Card Title </Card.Title>
                    <Card.Text style={{textAlign: "justify"}}>
                        {content}

                    </Card.Text>
                    <Row className="justify-content-md-right">
                        <Col></Col>
                        <Col><Button onClick={handleButton} variant="outline-info">Read More</Button></Col>
                        
                    </Row>
                   

                    </Card.Body>
                </Card>
            </Col>
           
        </Row>

    </div>;
}

export default SectionReverse;