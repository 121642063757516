import React from "react"
import { Image,Col, Row,Modal,Button } from "react-bootstrap"
import Section  from "../components/Section/Section.component"
import SectionReverse from "../components/SectionReverse/SectionReverse.component";
const Associates = () => {

  const content = "We offer a range of services including warehousing, logistics, assembly, packaging and light manufacturing. Our extensive scope and flexibility means that we can value-add for our customers at every stage in the supply chain process. As a commercial division of HELP, we are also a proud disability friendly workplace, with a 90 percent supported employee ratio.";
 
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Industrial Standards
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
 
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="associates-overlay">
      <hr/>
      <div > <h1> Associates</h1>
      <p>  We represent the following
technology/service partners</p>
      </div>
     
      <Row>
        <Col>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
        </Col>
        <Col>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
        </Col>
        <Col>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
        </Col>
        <Col>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
            <Image src="../../associate_1.jpg" width="300px" style={{opacity:1, margin:"10px"}}/>
            <br/>
        </Col>
      </Row>
      <hr/>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}

export default Associates
